<template>
	<div>
		<please-login v-if="!user"></please-login>
		<v-sheet v-else
			flat
			color="lightest"
			class="pa-5 mw-survey rounded-lg"
		>
			<template v-if="survey">
				<survey-inner
					:id="id"
					@save="save"
					@setUser="(v) => (participant = v)"
				></survey-inner>
			</template>
			<v-snackbar v-else color="primary" v-model="error" text>
				No survey found
			</v-snackbar>
		</v-sheet>
	</div>
</template>
<style lang="less"></style>
<script>

import PleaseLogin from "@c/ui/PleaseLogin"
import SurveyInner from "@c/survey/render/Survey.vue";
export default {
	name: "Rewards",
	props: {
		},
	data: () => {
		return {
			id: "PambZAkLatUBP8dnOirY",
			error: false,
			data: {},
			participant: false,
		};
	},
	components: {
		SurveyInner,
		PleaseLogin
	},
	methods: {
		save(v){
			const self = this;
			self.$store.dispatch( "rewards/insert", {
				user: self.user, 
				data: v.data
			}).then( () => {	
				self.$router.replace({
					name: "ThankYou",
					params: { id: self.id },
				});
			})
		}
	},
	computed: {
		user(){
			return this.$store.state.auth.data.id || false;
		},
        survey() {
            return this.$store.state.surveys.data[this.id];
        },
	},
	watch: {
		id: {
			immediate: true, 
			handler(v){
				if( v ){
					this.$store.dispatch("surveys/fetchById", v );
				}
			}
		}
	}
};
</script>
